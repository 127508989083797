<template>
  <div class="sheet-wrap">
    <a-form autocomplete="false" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" :form="formSheet">
      <div class="info-wrap">
        <InfoTitle title="基本信息" />
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="科目">
              <a-select
                allow-clear
                v-decorator="['subject', { rules: [{ required: true, message: '请选择科目!' }] }]"
                placeholder="科目"
                style="width: 100%"
                disabled
              >
                <a-select-option value="CHINESE"> 中文 </a-select-option>
                <a-select-option value="MATH"> 数学 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="班级名称">
              <a-input disabled placeholder="班级名称" v-decorator="['className']" />
            </a-form-item>
          </a-col>
        
               <a-col :span="8">
                <a-form-item label="班级类型">
                  <a-select
                    allow-clear
                      v-decorator="[
                      'classType',
                    ]"
                    placeholder="班级类型"
                    style="width: 100%"
                    disabled
                  >
                   <a-select-option :value="item.value" v-for="(item, index) in classTypeArr" :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
               
              </a-col>
             
           
        </a-row>
      </div>
      <div class="info-wrap">
        <InfoTitle title="发单课节信息" />
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="相关课节(北京)">
              <div>
                <div class="class-item" v-for="(subClassItem, index1) in classItem.scheduleArray" :key="index1">
                  <div v-if="subClassItem.courseSectionRespDTO" class="class-subItem">
                    {{
                      `${
                        subClassItem.courseSectionRespDTO.data.level3
                          ? '第' + subClassItem.courseSectionRespDTO.data.level3 + '课'
                          : ''
                      } 
                                ${subClassItem.scheduleName}`
                    }}
                    {{
                      subClassItem.courseSectionRespDTO.data.level1
                        ? subClassItem.courseSectionRespDTO.data.level1 + '-'
                        : ''
                    }}
                    {{
                      subClassItem.courseSectionRespDTO.data.level2
                        ? subClassItem.courseSectionRespDTO.data.level2 + '-'
                        : ''
                    }}
                    {{
                      subClassItem.courseSectionRespDTO.data.level3 ? subClassItem.courseSectionRespDTO.data.level3 : ''
                    }}
                    {{
                      `${$moment(subClassItem.startChinaDateTime).format('YYYY/MM/DD')} ${$moment(
                        subClassItem.startChinaDateTime,
                      ).format('dddd')} ${subClassItem.startChinaDateTime.substring(
                        10,
                      )} - ${subClassItem.endChinaDateTime.substring(11)}`
                    }}
                  </div>
                  <div v-else class="class-subItem">
                    {{ subClassItem.courseSectionName }}{{ subClassItem.scheduleName }}
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="授课语言">
              <a-select mode="multiple" v-decorator="['language']" placeholder="请选择">
                <a-select-option v-for="item in languageOptions" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="期望老师授课风格">
              <a-select v-decorator="['teachingStyle']" placeholder="请选择" mode="multiple">
                <a-select-option v-for="item in teachingStylesOptions" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="期望老师性别">
              <a-select v-decorator="['gender']" placeholder="请选择">
                <a-select-option v-for="item in genderArr" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <div class="info-wrap" style="height: 660px">
        <InfoTitle title="更多信息" />
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="发单有效期">
              <a-select
                allow-clear
                v-decorator="['expireTime', { rules: [{ required: true, message: '请选择发单有效期!' }] }]"
                placeholder="请选择"
                style="width: 100%"
              >
                <a-select-option :value="item.value" v-for="(item, index) in expireTimes" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="发单备注">
              <a-textarea placeholder="请输入" v-decorator="['remark']" :maxlength="255" :rows="1" />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="btn-wrap" style="text-align: center; padding-top: 40px">
          <a-button type="primary" @click="sendSheet">立即发单</a-button>
        </div>
      </div>
    </a-form>
    <a-modal
      title="确认"
      :visible="visibleCancel"
      @ok="cancelIssuing"
      @cancel="cancelTC"
      okText="确定"
      cancelText="放弃"
      :okButtonProps="{
        props: {
          loading: iconLoading,
        },
      }"
    >
      <div class="issuing-wrap">
        <div style="font-size: 16px; margin-bottom: 8px">
          确定发此<span style="color: #43d186">{{
            sendType === 'URGENT_SUBSTITUTE_ORDER' ? '紧急代课单' : '常规代课单'
          }}</span
          >吗？
        </div>
        <div>发单后，授课老师将进行抢单；授课老师抢单前您可取消该单</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import storage from 'store';
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import { languageOptions, dayOfWeekOptions, teachingStylesOptions } from '@/utils/const';
import { createClassOrder } from '@/api/headTeacher';
import {getOrderAgainSheetDetail} from '@/api/schedule'
const roles = storage.get('roles');

export default {
  name: 'courseSheet',
  components: { InfoTitle },
  data() {
    return {
    relationGroupId:'',
    formClassId:'',
    scheduleOrderId:'',
    processInstanceId:'',
      sendType: '',
      paramsObj: {},
      iconLoading: false,
      visibleCancel: false,
      courseScheduleIds: [],
      genderArr: [
        { label: '男', value: 'M' },
        { label: '女', value: 'F' },
        { label: '不确定', value: 'U' },
      ],
      classItem: {},
      expireTimes: [
        { label: '5分钟', value: 300 },
        { label: '10分钟', value: 600 },
        { label: '20分钟', value: 1200 },
        { label: '30分钟', value: 1800 },
        { label: '1小时', value: 3600 },
        { label: '2小时', value: 7200 },
        { label: '3小时', value: 10800 },
        { label: '6小时', value: 21600 },
        { label: '12小时', value: 43200 },
        { label: '24小时', value: 86400 },
        { label: '2天', value:172800 },
        { label: '3天', value:259200 },
        { label: '4天', value:345600},
        { label: '5天', value:432000 },
        { label: '6天', value:518400},
        { label: '7天', value:604800 },
      ],
      teachingStylesOptions,
      languageOptions,
      labelCol: { span: 6 },
      formSheet: this.$form.createForm(this),
      classTypeArr: [
        { label: '班课', value: 'GROUP' },
        { label: '私教课', value: 'PRIVATE' },
      ],
    };
  },
  methods: {
    moment,
    getOrderAgainSheetDetail(){
      let params={
        scheduleOrderId:this.scheduleOrderId
      }
      getOrderAgainSheetDetail(params).then(res=>{
      this.classItem=res.data
      console.log( this.classItem)
      this.courseScheduleIds=this.classItem.scheduleOrderRespDTO?.businessData?.courseScheduleIds;
      this.processInstanceId=this.classItem.scheduleOrderRespDTO?.businessData?.processInstanceId;
     this.formClassId=this.classItem?.scheduleOrderRespDTO?.formClassId;
     this.relationGroupId=this.classItem?.scheduleOrderRespDTO?.relationGroupId;
        this.formSheet.setFieldsValue({
          classType: this.classItem?.scheduleOrderRespDTO?.businessData?.classType,
          className: this.classItem?.scheduleOrderRespDTO?.formClassName,
          subject:this.classItem?.scheduleOrderRespDTO?.businessData?.subject,
          gender:this.classItem?.scheduleOrderRespDTO?.businessData?.gender || undefined,
          teachingStyle:this.classItem?.scheduleOrderRespDTO?.businessData?.teachingStyle || [],
          language:this.classItem?.scheduleOrderRespDTO?.businessData?.language || [],
          remark:this.classItem?.scheduleOrderRespDTO?.businessData?.customData?.remark,
          expireTime:this.classItem?.scheduleOrderRespDTO?.expireTime

        });
      
      })
    },
    cancelTC() {
      this.visibleCancel = false;
    },
    cancelIssuing() {
      this.iconLoading = true;
      createClassOrder(this.paramsObj)
        .then((res) => {
          this.$message.success('发单成功');
          this.iconLoading = false;
          this.visibleCancel = false;
          // 调至发单管理
          this.$router.push({ path: '/workoutSchedule/issuing' });
        })
        .catch((res) => {
          this.iconLoading = false;
          this.visibleCancel = false;
        });
    },
    sendSheet() {
      this.formSheet.validateFields((err, values) => {
        if (!err) {
          const formData = values;
          const params = {
            formClassId: this.formClassId,
            relationGroupId:this.relationGroupId,//关联单的分组ID
            expireTime: values.expireTime,
            relationSourceId:this.scheduleOrderId,
            businessData: {
              gender: values.gender,
              teachingStyle: values.teachingStyle,
              language: values.language,
              courseScheduleIds: this.courseScheduleIds,
              processInstanceId: this.processInstanceId,
              customData: {
                remark: values.remark,
              },
            },

            type: this.sendType,
          };
          this.paramsObj = params;
          this.visibleCancel = true;
        }
      });
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.sendType = this.$route.query.type;
    this.scheduleOrderId=this.$route.query.scheduleOrderId
  },
  mounted() {
    let formType=this.$route.query.formType
    //发单管理，再次发单进来,回填页面信息
    if(formType==='bill'){
  
    //调接口去获取详情
    this.getOrderAgainSheetDetail()
   

    }else{
        this.classItem = JSON.parse(this.$route.query.classItem);
        this.formClassId=this.classItem.uuid,
        this.processInstanceId=this.$route.query.processInstanceId
       this.courseScheduleIds = this.classItem.scheduleArray.map((item) => {
          return item.uuid;
        });
        this.formSheet.setFieldsValue({
          classType: this.classItem.classType,
          className: this.classItem.className,
          subject: this.classItem.scheduleArray[0].courseSectionRespDTO.course.subject,
        });
    }
   
  },
};
</script>

<style lang="less" scoped>
.info-wrap {
  background-color: #fff;
  padding: 18px 20px 0px;
  margin-bottom: 12px;
}
.sheet-wrap {
}
.shedule-period-wrapper .ant-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.class-item {
  margin-top: 9px;
}
.class-subItem {
  line-height: 24px;
}
</style>
