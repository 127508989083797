<template>
  <div class="title">
    <span>{{ title }}</span>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '标题',
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin-bottom:10px;
  &::before {
    content: '';
    display: block;
    float: left;
    margin: 4px 6px 0 0;
    width: 3px;
    height:16px;
    background-color:#41CC83;
    border-radius: 0px 2px 2px 0px;
  }
}
</style>
