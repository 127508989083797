import storage from 'store';
import { post, fetch, put, DELETE } from '@/api';

const BASE_URL = process.env.VUE_APP_API_IM_URL;

let timezoneList = [];
// const classAdminList = [];
let scheduleOptions = {
  subject: [],
  subjectType: [],
  courseType: [],
  standardType: [],
  feeType: [],
  classType: [],
  classStatus: [],
  scheduleStatus: [],
  stuScheduleStatus: [],
  stuScheduleStatusReason: [],
  timeSplitItemStatus: [],
  courseStructure: [],
  formClassCapacityStatus: [],
  workStatus: [],
  leaveStatus: [],
  attendanceStatus: [],
  workType: [],
  cuTransType: [],
  approveStatus: [],
  taskLogType: [],
  userRole: [],
  lossRefundType: [],
  lossRefundReason: null,
  shelfStatus: [],
};

/**
 * @param  {[parameter]}
 * @return {[Promise]}
 */
export function postReq(url, parameter, config) {
  return post(BASE_URL, url, parameter, config);
}
export function getReq(url, parameter) {
  return fetch(BASE_URL, url, parameter);
}
export function putReq(url, parameter) {
  return put(BASE_URL, url, parameter);
}
export function deleteReq(url, parameter) {
  return DELETE(BASE_URL, url, parameter);
}

// 加载时区列表
export function loadTimezoneList(success) {
  if (timezoneList.length > 0) {
    !!success && success(timezoneList);
    return;
  }

  fetch(BASE_URL, '/public/ref/timezones/ddl', {}).then((res) => {
    timezoneList = res.data.content || [];
    if (success) success(timezoneList);
  });
}
// 加载排课系统下拉选项
export function loadScheduleOptions(success) {
  if (scheduleOptions.subject.length > 0) {
    !!success && success(scheduleOptions);
    return;
  }

  fetch(BASE_URL, '/api/admin/scheduling/options', {}).then((res) => {
    scheduleOptions = res.data.content || {};
    if (success) success(scheduleOptions);
  });
}
// 加载班主任下拉选项
// export function loadClassAdminOptions(success,params) {
//   if (classAdminList.length > 0) {
//     !!success && success(classAdminList);
//     return;
//   }

//   fetch(BASE_URL, '/api/admin/class_admin/ddl',params).then(res => {
//     classAdminList = res.data.content || [];
//     if (success) success(classAdminList);
//   });
// }

export function loadClassAdminOptions(parameter) {
  return fetch(BASE_URL, '/api/admin/class_admin/ddl', parameter);
}

// 校验当前排课级别与指定的授课老师可排级别
export function checkTeacherClass(parameter) {
  return post(BASE_URL, '/api/teacher/work_flow/course_section/ability', parameter);
}
// 校验是否是紧急排课单
export function checkUrgentClass(parameter) {
  return post(BASE_URL, '/api/admin/scheduling/date_time/check', parameter);
}
// 发排课单
export function sendScheduleSheet(parameter) {
  return post(BASE_URL, '/api/admin/teacher/schedule/order/create', parameter);
}
// 获取搜索老师信息的接口
export function queryTeacherInfo(parameter) {
  return fetch(BASE_URL, `/api/teacher/work_flow/detail/${parameter.teacherId}`);
}
// 校验系统可用时间
export function checkHoliday(parameter) {
  return post(BASE_URL, '/api/admin/teacher/schedule/order/create/holiday/check', parameter);
}
// 检查传入的课节是否满足 紧急指定发单
export function checkCourseSchedule(parameter) {
  return post(BASE_URL, '/api/admin/scheduling/change/teacher/check_course_schedule_in_24_hours', parameter);
}
// 检查调时间传入的课节是否满足 紧急指定发单
export function checkAdjustDateSchedule(parameter) {
  return post(
    BASE_URL,
    '/api/admin/scheduling/course_schedule/adjust_date/check_course_schedule_in_24_hours',
    parameter,
  );
}
// 检查调整课节星期时间传入的课节是否满足
export function checkAdjustWeekSchedule(parameter) {
  return post(
    BASE_URL,
    '/api/admin/scheduling/course_schedule/adjust_week/batch/check_course_schedule_in_24_hours',
    parameter,
  );
}
// 校验节假日
export function checkTeacherHoliday(parameter) {
  return post(BASE_URL, '/api/admin/teacher/order/urgent_specify_teacher/create/holiday/check', parameter);
}
// 创建紧急指定单
export function createTeacherSheet(parameter) {
  return post(BASE_URL, '/api/admin/teacher/order/urgent_specify_teacher/create', parameter);
}
// 获取课节监课跟回放
export function queryPlayCourse(parameter) {
  return fetch(BASE_URL, `/api/student/schedule/supervised_playback`, parameter);
}
// 获取课节监课跟回放
export function getVideoRecord(roomId) {
  return fetch(BASE_URL, `/api/teacher/work_flow/assess/video/record/${roomId}`);
}
// 获取再次发单详情
export function getOrderAgainSheetDetail(parameter) {
  return fetch(BASE_URL, '/api/admin/teacher/order/get_order_again_parameter', parameter);
}
// 获取“去排课”默认上课平台
export function getDefaultPlatform(parameter) {
  return fetch(BASE_URL, `/api/student/schedule/lately/trail/platform/${parameter.uuid}`, parameter);
}

// 获取学员课堂数据

export const getSheduleTimeLine = (params, pageNum) => {
  return post(BASE_URL, `/api/admin/student/course_schedule/time_line/${pageNum}`, params);
};

// 复制上课链接
export const createClassLink = (params) => {
  return post(BASE_URL, `/api/admin/scheduling/login_link`, params);
};
// 过滤课程中的文案
const filterOptions = (v) => {
  return !/test|测试|班主任勿用|回归/gi.test(v);
};
// 统一封装获取课程
export const getCourse = async (params) => {
  const userInfo = storage.get('userInfo');
  const isRole = userInfo?.roleArr.find((value) => value === 'CLASS_ADMIN') && !userInfo?.leader;
  const url = `/api/admin/scheduling/course/search`;
  if (isRole) {
    // 班主任并且非管理岗
    params.courseType = 'STANDARD';
    const res = await post(BASE_URL, url, params);
    let content = res?.data?.content || [];
    content = content.filter((item) => filterOptions(item.courseName));
    return content;
  }
  const res = await post(BASE_URL, url, params);
  return res?.data?.content || [];
};

// 获取拓客云房间号
export const getTalkCloudRoomNumber = (courseScheduleId) => {
  return fetch(BASE_URL, `/api/admin/scheduling/talk_cloud/roomId/${courseScheduleId}`);
};

// 获取学员课节消耗课时
export const getCourseUnitTransaction = (stuScheduleId) => {
  return fetch(BASE_URL, `/api/student/course_unit/transaction?stuScheduleId=${stuScheduleId}`);
};

// 创建课节反馈
export const createCourseFeedback = (params) => {
  return post(BASE_URL, `/api/feedback/create/feedback`, params);
};

export const getFeedbackListByCourseScheduleId = (courseScheduleId) => {
  return fetch(BASE_URL, `/api/feedback/list/by/courseScheduleId/${courseScheduleId}`);
}
// 发送学员账号验证邮件
export const sendVerifyEmail = (data) => {
  return post(BASE_URL, `/api/student/parentalVerification/email/inside/send`, data);
};

export const queryScheduleOrderForClass = (data) => {
  return post(BASE_URL, `/api/admin/teacher/schedule/order/find`, data)
}